<script>
import { Line } from 'vue-chartjs'

export default {
  name: 'ChartsComp',
  extends: Line,
  // mixins: [mixins.reactiveProp],
  props: ['chartData'],
  watch: {
    chartData() {
      // this.$data._chart.update();
      this.render()
    },
  },
  methods: {
    render() {
      this.renderChart(this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          gridLines: {
            display: false,
          },
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                suggestedMax: 10,
                suggestedMin: 0,
              },
            },
          ],
        },
      })
    },
  },
}
</script>
