var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    staticClass: "container",
    attrs: {
      "flex-dir": "column",
      "w": "full"
    }
  }, [_c('c-flex', [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-weight": "500",
      "font-size": "lg"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(_vm.props.date, 'YYYY-MM-DD HH:mm')) + " ")]), _c('c-link', {
    attrs: {
      "d": "flex"
    },
    on: {
      "click": function click($event) {
        return _vm.emit('click', _vm.id);
      }
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-size": "md"
    }
  }, [_vm._v(" Lihat Detail ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icon-chevron-right.svg'),
      "h": "24px",
      "w": "24px"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "10px",
      "w": "full",
      "grid-gap": "70px",
      "py": "15px",
      "px": "25px",
      "border-width": "1px",
      "border-color": "#c4c4c4",
      "rounded": "md"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "grid-gap": "5px"
    }
  }, [_c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-scale-outline.svg'),
      "text": "Berat Badan",
      "value": "".concat(_vm.props.weight || '-', " kg")
    }
  }), _c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-waist-outline.svg'),
      "text": "Lingkar Pinggang",
      "value": "".concat(_vm.props.waist || '-', " cm")
    }
  }), _c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-brand-asana.svg'),
      "text": "Persen Lemak",
      "value": "".concat(_vm.props.bodyFat || '-', " %")
    }
  }), _c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-muscle.svg'),
      "text": "Massa Otot",
      "value": "".concat(_vm.props.muscleMass || '-', " kg")
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex": "1",
      "flex-dir": "column",
      "grid-gap": "5px"
    }
  }, [_c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-droplet.svg'),
      "text": "Cairan Tubuh",
      "value": "".concat(_vm.props.bodyWater || '-', " %")
    }
  }), _c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-brand-asana.svg'),
      "text": "Lemak Visceral",
      "value": "".concat(_vm.props.visceralFat || '-', " %")
    }
  }), _c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-bone.svg'),
      "text": "Massa Tulang",
      "value": "".concat(_vm.props.boneMass || '-', " kg")
    }
  }), _c('CardTextLine', {
    attrs: {
      "icon": require('@/assets/ic-man.svg'),
      "text": "BMR",
      "value": _vm.props.bmr || '-'
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }