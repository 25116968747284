import _ from 'lodash'
import tinycolor from 'tinycolor2'
import { formatDate } from '@/utils/format-date'

const colors = {
  weight: tinycolor('#008C81'),
  waist: tinycolor('#008C81'),
  bodyFat: tinycolor('#008C81'),
  muscleMass: tinycolor('#008C81'),
  bodyWater: tinycolor('#008C81'),
  visceralFat: tinycolor('#008C81'),
  boneMass: tinycolor('#008C81'),
  BMR: tinycolor('#008C81'),
}

export function getChartData(progressList, chartFilter) {
  const labels = getLabels(progressList)
  const datasets = getDatasets(progressList, chartFilter)
  return {
    labels,
    datasets,
  }
}

export function getLabels(list) {
  if (list.length === 0) return []
  return list.map((it) => {
    return formatDate(it.date, 'DD MMM')
  })
}

export function getDatasets(progressList, chartFilter) {
  let list = _.orderBy(progressList, 'date', 'asc')

  if (list.length === 0) return []
  const basic = { lineTension: 0 }

  let data = []

  if (chartFilter == null || chartFilter === 'weight') {
    data.push({
      ...basic,
      label: 'Berat Badan',
      data: list.map((it) => it.overview.weight),
      pointBackgroundColor: colors.weight.toHex8String(),
      borderColor: colors.weight.toHex8String(),
      backgroundColor: colors.weight.clone().setAlpha(0.1).toHex8String(),
    })
  }
  if (chartFilter == null || chartFilter === 'waist') {
    data.push({
      ...basic,
      label: 'Lingkar Pinggang',
      data: list.map((it) => it.overview.waist),
      pointBackgroundColor: colors.waist.toHex8String(),
      borderColor: colors.waist.toHex8String(),
      backgroundColor: colors.waist.clone().setAlpha(0.2).toHex8String(),
    })
  }
  if (chartFilter == null || chartFilter === 'bodyFat') {
    data.push({
      ...basic,
      label: 'Persen Lemak',
      data: list.map((it) => it.overview.bodyFat),
      pointBackgroundColor: colors.bodyFat.toHex8String(),
      borderColor: colors.bodyFat.toHex8String(),
      backgroundColor: colors.bodyFat.clone().setAlpha(0.2).toHex8String(),
    })
  }
  if (chartFilter == null || chartFilter === 'muscleMass') {
    data.push({
      ...basic,
      label: 'Massa Otot',
      data: list.map((it) => it.overview.muscleMass),
      pointBackgroundColor: colors.muscleMass.toHex8String(),
      borderColor: colors.muscleMass.toHex8String(),
      backgroundColor: colors.muscleMass.clone().setAlpha(0.2).toHex8String(),
    })
  }
  if (chartFilter == null || chartFilter === 'bodyWater') {
    data.push({
      ...basic,
      label: 'Cairan Tubuh',
      data: list.map((it) => it.overview.bodyWater),
      pointBackgroundColor: colors.bodyWater.toHex8String(),
      borderColor: colors.bodyWater.toHex8String(),
      backgroundColor: colors.bodyWater.clone().setAlpha(0.2).toHex8String(),
    })
  }
  if (chartFilter == null || chartFilter === 'visceralFat') {
    data.push({
      ...basic,
      label: 'Lemak Visceral',
      data: list.map((it) => it.overview.visceralFat),
      pointBackgroundColor: colors.visceralFat.toHex8String(),
      borderColor: colors.visceralFat.toHex8String(),
      backgroundColor: colors.visceralFat.clone().setAlpha(0.2).toHex8String(),
    })
  }
  if (chartFilter == null || chartFilter === 'boneMass') {
    data.push({
      ...basic,
      label: 'Massa Tulang',
      data: list.map((it) => it.overview.boneMass),
      pointBackgroundColor: colors.boneMass.toHex8String(),
      borderColor: colors.boneMass.toHex8String(),
      backgroundColor: colors.boneMass.clone().setAlpha(0.2).toHex8String(),
    })
  }
  if (chartFilter == null || chartFilter === 'BMR') {
    data.push({
      ...basic,
      label: 'BMR',
      data: list.map((it) => it.overview.BMR),
      pointBackgroundColor: colors.BMR.toHex8String(),
      borderColor: colors.BMR.toHex8String(),
      backgroundColor: colors.BMR.clone().setAlpha(0.2).toHex8String(),
    })
  }

  return data
}
