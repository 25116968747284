var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.icon,
      "w": "20px",
      "h": "20px"
    }
  }), _c('c-text', {
    attrs: {
      "ml": "10px",
      "flex": "1",
      "color": "grey",
      "font-size": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]), _c('c-text', {
    attrs: {
      "ml": "10px",
      "font-size": "18px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.value) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }