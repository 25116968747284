<template>
  <c-flex align-items="center">
    <c-image
      :src="icon"
      w="20px"
      h="20px"
    />
    <c-text
      ml="10px"
      flex="1"
      color="grey"
      font-size="18px"
    >
      {{ text }}
    </c-text>
    <c-text
      ml="10px"
      font-size="18px"
      font-weight="500"
    >
      {{ value }}
    </c-text>
  </c-flex>
</template>

<script>    
export default {
  name: 'CardTextLine',
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
}
</script>
